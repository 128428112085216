import React from 'react';
import OriginateClient from '../../Providers/OriginateClient';

const selfHosted = () => {
  let hostname = window.location.hostname.toLowerCase();

  if (!hostname.includes('originate') && !hostname.includes('indicina')) {
    return true;
  }
};

const OriginateLogo = () => (
  <OriginateClient
    render={({ clientId }) => (
      <span>
        {selfHosted() ? (
          <img height={40} alt="Logo" src={clientId.clientInfo.logoUrl} />
        ) : (
          <img
            src="/originate-brand-white.png"
            alt="Originate Logo"
            height={40}
          />
        )}
      </span>
    )}
  ></OriginateClient>
);

export default OriginateLogo;
