import { gql } from 'apollo-boost';

const PORTFOLIO_FRAGMENT = `
    on Portfolio {
        id
        portfolioNumber
        policy{
          id 
          durationType
          }
        user {
          id
          firstName
          lastName
          email
          phone
          blacklistDetails {
            blacklisted
            isInternalBlacklist
            reports {
              id
              reason
              isSameEmail
              isSamePhone
              isSameBvn
              actionBy {
                id
                firstName
                lastName
              }
            }
          }
        }
        nibssMandateId
        amount
        status {
          name
        }
        application {
            id
            applicationNumber
            loanCategory {
              id
              name
              products {
                processingFeeName
                id
              }
            }
            approvalWorkflow {
                id
                nextStep {
                    id
                    approvalType
                }
            }
            uploadedBankStatement {
              url
            }
            credit { salaryDay }
            reviewFeedbacks {
              id
              status
              reviewType
              documentRequests {
                id
                title
                message
                document {
                  id
                    documentName
                    file {
                      key
                      bucket
                    }
                }
              }
            }
            meta {
              key
              value
              document {
                file {
                  key
                  bucket
                }
              }
            }
        }
        debitMandate{
          id
          history{
            updatedAt
            createdAt
            comment
            newStatus{
              name
            }
          }
          formUrl
          mandateId
          status{
            name
          }
        }
        debitMandateMeta {
          id
          activationDate
          status {
            name
          }
          type {
            id
            name
          }
          recovaMandate {
            id
            consentApprovalUrl
          }
          histories {
            nodes {
              id
              comment
              action
              updatedAt
              newStatus {
                name
              }
            }
          }
          lidyaCollect {
            id
            collectId
          }
        }
        amount
        baseAmount
        taxAmount
        chargesAmount
        fullAmount
        amountPaid
        amountDisbursed
        loanDuration
        dateOfRepayment
        processingFee
        lateRepaymentFee
        createdAt
        transactions (where: $transactionWhere) @connection(key: "TransactionConnection") {
          nodes {
            id
            type {
              name
            }
            status
            amount
            paymentMethod {
              name
            }
            createdAt
            status
          }
        }
        histories (
          first: $first
          after: $cursor
          orderBy: createdAt_DESC
          where: $where
          ) @connection(key: "portfolioHistoryConnection") {
            pageInfo {
              startCursor
              endCursor
              hasNextPage
              hasPreviousPage
            }
            nodes {
              id
              newStatus { name }
              oldStatus { name }
              comment
              actionBy {
                firstName
                lastName
              }
              updatedAt
            }
            totalCount
          }
        repayments{
            id
            amountPaid
            interestPortion
            principalPortion
            principalBalance
            totalPayment
            lateFee
            repaymentDate
            outstandingPayment
            dueDate
            status{
                name
            }
            isRemitaRepayment
            metadata {
              value
              key
            }
            mandateInstruction {
              id
              status {
                name
              }
            }
        }
         offlineRepayments {
              id
              status
              bank{
                id
                name
              }
              user{
                id
                firstName
                lastName
                email
              }
              portfolio{
                id
                portfolioNumber
              }
              createdAt
         }
      }
`;

export const PORTFOLIO_BY_ID_QUERY = gql`
    query MP_PortfolioByIdQuery($id: ID!) {
      portfolioById(id: $id) {
        ...${PORTFOLIO_FRAGMENT}
      }
    }
`;

export const CUSTOMER_BY_ID_QUERY = gql`
    query MP_CustomerByIdQuery(
    $customerId: ID! 
    $first: ConnectionLimitInt
    $cursor: ConnectionCursor
    $transactionWhere: TransactionWhereInput
    $where: PortfolioHistoryWhereInput
    ) {
      portfolio(input: { customerId: $customerId }) {
        ...${PORTFOLIO_FRAGMENT}
      }
    }
`;

export const PORTFOLIO_SUMMARY_QUERY = gql`
  query MP_PortfolioSummaryQuery {
    portfolioSummary {
      totalLoans
      totalActiveLoans
      sumTotalLoans
      numberOfActiveLoans
    }
  }
`;

export const PORTFOLIO_QUERY = gql`
      query MP_Portfolio(
        $portfolioNumber: String!
        $first: ConnectionLimitInt
        $cursor: ConnectionCursor
        $transactionWhere: TransactionWhereInput
        $where: PortfolioHistoryWhereInput
        ) {
          portfolio(portfolioNumber: $portfolioNumber) {
            ...${PORTFOLIO_FRAGMENT}
            }
          }
`;
