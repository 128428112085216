import { gql } from 'apollo-boost';

export const fragments = {
  user: gql`
    fragment UserDetails on User {
      id
      firstName
      lastName
      email
      phone
      bvnStatus {
        id
        bvn
        gender
        dateOfBirth
      }
      isEmailConfirmed
      isPhoneConfirmed
      blacklistDetails {
        blacklisted
        isInternalBlacklist
        reports {
          id
          reason
          isSameEmail
          isSamePhone
          isSameBvn
          createdAt
          actionBy {
            firstName
            lastName
          }
        }
      }
    }
  `,

  customerAccountAttribute: gql`
    fragment CustomerAccountAttributeDetails on CustomerAccountAttribute {
      attribute {
        name
      }
      value
      data
    }
  `,

  portfolio: gql`
    fragment PortfolioDetails on Portfolio {
      id
      portfolioNumber
      amountDisbursed
      user {
        id
        email
        firstName
        lastName
      }
      chargesAmount
      repayments {
        id
        interestPortion
        repaymentDate
        outstandingPayment
      }
    }
  `,
};

export const CUSTOMER_PORTFOLIOS_QUERY = gql`
  query MP_CustomerPortfoliosQuery(
    $cursor: ConnectionCursor
    $first: ConnectionLimitInt
    $skipAccountAttributes: Boolean!
    $id: String
    $email: String
    $name: String
    $status: String
    $amountLt: Float
    $amountGt: Float
    $repaymentAmountLt: Float
    $repaymentAmountGt: Float
    $loanDateLt: DateTime
    $loanDateGt: DateTime
    $repaymentDateLt: DateTime
    $repaymentDateGt: DateTime
  ) {
    viewer {
      account {
        customerPortfolios(
          first: $first
          after: $cursor
          orderBy: createdAt_DESC
          where: {
            portfolioNumber: $id
            status: $status
            amount_lte: $amountGt
            amount_gte: $amountLt
            fullAmount_lte: $repaymentAmountGt
            fullAmount_gte: $repaymentAmountLt
            createdAt_lt: $loanDateLt
            createdAt_gte: $loanDateGt
            dateOfRepayment_lt: $repaymentDateLt
            dateOfRepayment_gte: $repaymentDateGt
            customer: { email_contains: $email, fullName_contains: $name }
          }
        ) @connection(key: "customerPortfolios") {
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          nodes {
            id
            portfolioNumber
            amount
            status {
              name
            }
            user {
              id
              firstName
              lastName
              email
            }
            application @skip(if: $skipAccountAttributes) {
              account {
                accountAttribute {
                  ...CustomerAccountAttributeDetails
                }
              }
            }
            dateOfRepayment
            fullAmount
          }
          totalCount
        }
      }
    }
  }

  ${fragments.customerAccountAttribute}
`;

export const CUSTOMERS_QUERY = gql`
  query MP_CustomersQuery(
    $cursor: ConnectionCursor
    $first: ConnectionLimitInt
    $email: String
    $name: String
    $phone: String
    $customerId: String
    $createdAtLt: DateTime
    $createdAtGt: DateTime
  ) {
    viewer {
      account {
        customers(
          first: $first
          after: $cursor
          orderBy: createdAt_DESC
          where: {
            email_contains: $email
            phone: $phone
            name: $customerId
            fullName_contains: $name
            createdAt_lt: $createdAtGt
            createdAt_gte: $createdAtLt
          }
        ) @connection(key: "customers") {
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          nodes {
            id
            name
            user {
              ...UserDetails
            }
            accountAttribute {
              ...CustomerAccountAttributeDetails
            }
            createdAt
          }
        }
      }
    }
  }

  ${fragments.user}
  ${fragments.customerAccountAttribute}
`;

export const CREDIT_POLICIES_QUERY = gql`
  query MP_CreditPoliciesQuery(
    $first: ConnectionLimitInt
    $cursor: ConnectionCursor
    $name: String
    $status: String
    $minLoanAmount: Float
    $maxLoanAmount: Float
    $minLoanDuration: Int
    $maxLoanDuration: Int
    $durationType: String
    $repaymentType: String
  ) {
    viewer {
      account {
        policies(
          first: $first
          after: $cursor
          orderBy: createdAt_DESC
          where: {
            status: $status
            name_contains: $name
            minLoanAmount_gte: $minLoanAmount
            maxLoanAmount_lte: $maxLoanAmount
            minLoanDuration_gte: $minLoanDuration
            maxLoanDuration_lte: $maxLoanDuration
            durationTypeName: $durationType
            repaymentTypeName: $repaymentType
          }
        ) @connection(key: "policy") {
          nodes {
            id
            name
            status
            minLoanAmount
            maxLoanAmount
            minLoanDuration
            maxLoanDuration
            maxLateFeeDuration
            durationType
            repaymentType
            createdAt
            priority
            loanType
            creator {
              id
              firstName
              email
            }
            approvalWorkflow {
              id
              name
              status
              isCustom
            }
            lateFeeCalculation {
              lateFeeFrequency
              lateFeeChargeOn
              penaltyDelay
              rate {
                name
                calcBy
                value
              }
              applyFlatLateFeePerPeriod
            }
            startDate
            endDate
            processingFee {
              name
              calcBy
              value
            }
            baseRuleSet {
              id
            }
            interestRate {
              value
              name
              calcBy
            }
            graduatedLoanCycles {
              maxLoanDuration
              amount
              interestRate
              repaymentCount
            }
            interestCalculationMethod {
              description
              name
            }
            allowEarlyRepayment
            requiresDebitMandate
            offerLetterEnabled
            validityInDays
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
        }
      }
    }
  }
`;

export const CREDIT_POLICIES_DROPDOWN_QUERY = gql`
  query MP_CreditPoliciesQuery($status: String, $first: ConnectionLimitInt) {
    viewer {
      account {
        policies(first: $first, where: { status: $status }) {
          nodes {
            id
            name
            status
          }
        }
      }
    }
  }
`;

export const CREDIT_POLICIES_DETAILS_QUERY = gql`
  query MP_CreditPoliciesDetailsQuery(
    $first: ConnectionLimitInt
    $cursor: ConnectionCursor
    $policyId: ID
  ) {
    viewer {
      account {
        policies(where: { id: $policyId }) {
          nodes {
            id
            name
            status
            durationType
            minLoanAmount
            maxLoanAmount
            minLoanDuration
            maxLoanDuration
            maxLateFeeDuration
            repaymentType
            loanType
            graduatedLoanCycles {
              maxLoanDuration
              amount
              interestRate
            }
            createdAt
            portfolios(first: $first, after: $cursor, orderBy: createdAt_DESC)
              @connection(key: "portfolios") {
              pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
              }
              nodes {
                id
                fullAmount
                loanDuration
                amountPaid
                amountDisbursed
                portfolioNumber
                amount
                createdAt
                user {
                  ...UserDetails
                }
              }
            }
            applications(
              first: $first
              after: $cursor
              orderBy: createdAt_DESC
            ) @connection(key: "applications") {
              pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
              }
              nodes {
                id
                amount
                applicationNumber
                fullAmount
                loanDuration
                chargesAmount
                baseAmount
                taxAmount
                createdAt
                user {
                  ...UserDetails
                }
              }
            }
          }
        }
      }
    }
  }

  ${fragments.user}
`;

export const APPLICATIONS_QUERY = gql`
  query MP_ApplicationsQuery(
    $cursor: ConnectionCursor
    $first: ConnectionLimitInt
    $skipAccountAttributes: Boolean!
    $id: String
    $email: String
    $name: String
    $status: String
    $amountLt: Float
    $amountGt: Float
    $repaymentAmountLt: Float
    $repaymentAmountGt: Float
    $applicationDateLt: DateTime
    $applicationDateGt: DateTime
    $repaymentDateLt: DateTime
    $repaymentDateGt: DateTime
  ) {
    viewer {
      account {
        customerApplications(
          first: $first
          after: $cursor
          orderBy: createdAt_DESC
          where: {
            applicationNumber: $id
            status: $status
            amount_lte: $amountGt
            amount_gte: $amountLt
            fullAmount_lte: $repaymentAmountGt
            fullAmount_gte: $repaymentAmountLt
            createdAt_lt: $applicationDateLt
            createdAt_gte: $applicationDateGt
            dateOfRepayment_lt: $repaymentDateLt
            dateOfRepayment_gte: $repaymentDateGt
            customer: { email_contains: $email, fullName_contains: $name }
          }
        ) @connection(key: "applications") {
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          nodes {
            id
            applicationNumber
            amount
            fullAmount
            status {
              name
            }
            user {
              id
              firstName
              lastName
              email
            }
            account @skip(if: $skipAccountAttributes) {
              accountAttribute {
                ...CustomerAccountAttributeDetails
              }
            }
            policy {
              id
              name
              status
            }
            portfolio {
              fullAmount
            }
            createdAt
            completedSteps
            requiredSteps
          }
        }
      }
    }
  }

  ${fragments.customerAccountAttribute}
`;

export const TRANSACTIONS_QUERY = gql`
  query MP_TransactionsQuery(
    $cursor: ConnectionCursor
    $disbursementWalletId: ID
    $disbursementWalletAmount: Float
    $disbursementWalletType: String
    $disbursementWalletStatus: String
    $dWCreatedAtLt: DateTime
    $dWCreatedAtGt: DateTime
    $first: ConnectionLimitInt
  ) {
    getWallets: viewer {
      account {
        wallets {
          disbursementWallet {
            walletType {
              name
              prefix
            }
            amount
            transactions(
              first: $first
              after: $cursor
              orderBy: createdAt_DESC
              where: {
                portfolioNumber: $disbursementWalletId
                amount: $disbursementWalletAmount
                type: $disbursementWalletType
                status: $disbursementWalletStatus
                createdAt_lt: $dWCreatedAtGt
                createdAt_gte: $dWCreatedAtLt
              }
            ) @connection(key: "collection_wallet_transactions") {
              pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
              }
              nodes {
                id
                amount
                type {
                  name
                }
                createdAt
                status
                accountDetails {
                  accountNumber
                  bankName
                }
                portfolio {
                  ...PortfolioDetails
                }
              }
            }
          }
        }
      }
    }
  }

  ${fragments.portfolio}
`;

export const TRANSACTIONS_SUMMARY_QUERY = gql`
  query MP_TransactionsSummaryQuery {
    getWalletSummary: viewer {
      account {
        wallets {
          disbursementWallet {
            amount
          }
          walletSummary {
            totalCollection
            totalInterest
            totalDisbursment
          }
        }
      }
    }
  }
`;

export const INTEREST_TRANSACTIONS_QUERY = gql`
  query MP_InterestTransactionsQuery(
    $cursor: ConnectionCursor
    $feeWalletId: ID
    $feeWalletAmount: Float
    $feeWalletType: String
    $feeWalletTypeNot: String
    $feeWalletStatus: String
    $fWCreatedAtLt: DateTime
    $fWCreatedAtGt: DateTime
    $first: ConnectionLimitInt
  ) {
    getInterestWallets: viewer {
      account {
        wallets {
          feeWallet {
            walletType {
              name
              prefix
            }
            amount
            transactions(
              first: $first
              after: $cursor
              orderBy: createdAt_DESC
              where: {
                portfolioNumber: $feeWalletId
                amount: $feeWalletAmount
                type: $feeWalletType
                type_not: $feeWalletTypeNot
                status: $feeWalletStatus
                createdAt_lt: $fWCreatedAtGt
                createdAt_gte: $fWCreatedAtLt
              }
            ) @connection(key: "fee_wallet_transactions") {
              pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
              }
              nodes {
                id
                amount
                type {
                  name
                }
                createdAt
                status
                portfolio {
                  id
                  portfolioNumber
                  user {
                    id
                    email
                  }
                  chargesAmount
                  repayments {
                    id
                    interestPortion
                    outstandingPayment
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const COLLECTIONS_TRANSACTIONS_QUERY = gql`
  query MP_CollectionsTransactionsQuery(
    $cursor: ConnectionCursor
    $collectionType: String
    $feeWalletStatus: String
    $fWCreatedAtLt: DateTime
    $fWCreatedAtGt: DateTime
    $first: ConnectionLimitInt
  ) {
    getCollections: viewer {
      account {
        wallets {
          feeWallet {
            walletType {
              name
              prefix
            }
            amount
            transactions(
              first: $first
              after: $cursor
              orderBy: createdAt_DESC
              where: {
                type_contains: $collectionType
                status: $feeWalletStatus
                createdAt_lt: $fWCreatedAtGt
                createdAt_gte: $fWCreatedAtLt
              }
            ) @connection(key: "transactions") {
              pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
              }
              nodes {
                id
                amount
                type {
                  name
                }
                createdAt
                status
                portfolio {
                  id
                  portfolioNumber
                  user {
                    id
                    email
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const APPLICABLE_CUSTOMER_APPLICATIONS_QUERY = gql`
  query MP_ApplicableCustomerApplicationsQuery(
    $cursor: ConnectionCursor
    $first: ConnectionLimitInt
    $id: String
    $email: String
    $name: String
    $status: String
    $amountLt: Float
    $amountGt: Float
    $repaymentAmountLt: Float
    $repaymentAmountGt: Float
    $applicationDateLt: DateTime
    $applicationDateGt: DateTime
    $repaymentDateLt: DateTime
    $repaymentDateGt: DateTime
  ) {
    viewer {
      account {
        applicableCustomerApplications(
          first: $first
          after: $cursor
          orderBy: createdAt_DESC
          where: {
            applicationNumber: $id
            status: $status
            amount_lte: $amountGt
            amount_gte: $amountLt
            fullAmount_lte: $repaymentAmountGt
            fullAmount_gte: $repaymentAmountLt
            createdAt_lt: $applicationDateLt
            createdAt_gte: $applicationDateGt
            dateOfRepayment_lt: $repaymentDateLt
            dateOfRepayment_gte: $repaymentDateGt
            customer: { email_contains: $email, fullName_contains: $name }
          }
        ) @connection(key: "applicableCustomerApplications") {
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          nodes {
            id
            applicationNumber
            amount
            baseAmount
            taxAmount
            fullAmount
            chargesAmount
            status {
              name
            }
            user {
              ...UserDetails
            }
            createdAt
            loanDuration
            dateOfRepayment
            reviewFeedbacks {
              status
              reviewType
            }
            approvalWorkflow {
              id
              workflow {
                id
                name
                status
                isCustom
                steps {
                  id
                  approvalType
                }
              }
              nextStep {
                id
                approvalType
              }
            }
          }
        }
      }
    }
  }

  ${fragments.user}
`;

export const CUSTOMERS_VIEW_QUERY = gql`
  query MP_CustomersViewQuery(
    $first: Int
    $email: String
    $name: String
    $phone: String
    $customerId: String
    $createdAtLt: DateTime
    $createdAtGt: DateTime
    $facilityName: Boolean
    $nextCursor: ID
  ) {
    views {
      customers(
        first: $first
        nextCursor: $nextCursor
        where: {
          email: $email
          phone: $phone
          customerId: $customerId
          name: $name
          createdAt_lt: $createdAtLt
          createdAt_gte: $createdAtGt
        }
        facilityName: $facilityName
      ) @connection(key: "customers") {
        pageInfo {
          hasNextPage
          nextCursor
        }
        nodes {
          customerId
          contactEmail
          phoneNumber
          fullName
          createdAt
          facilityName
        }
      }
    }
  }
`;
